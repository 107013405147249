.avatar {
  position: relative;
  display: inline-block;
  width: 3rem;
  height: 3rem;
}

@media (min-width: 768px) {
  .avatar-xxl {
    width: 8rem;
    height: 8rem;
  }
}

.avatar-xxl {
  width: 5.125rem;
  height: 5.125rem;
}

.avatar-xl {
  width: 5.125rem;
  height: 5.125rem;
}

.avatar-lg {
  width: 4rem;
  height: 4rem;
}

.avatar-sm {
  width: 2.5rem;
  height: 2.5rem;
}
