/* You can add global styles to this file, and also import other style files */

body {
  font-family: 'Noto Sans', sans-serif;
}

* {
  outline: 0;
}

.sr-only {
  display: none;
}

.bg-transparent {
  background-color: #2542606e !important;
  ;
}

.btn-outline-success {
  border-color: #e2e2e2;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #254260;
  border-color: #ffffff;
}

.table-responsive {
  overflow-x: inherit;
}

.bg-overlay {
  background-color: #000;
  opacity: 0.7;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.text-danger {
  color: #ca1d1d !important;
}

.header-absolute {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 2;
}

.bg-alternate-dark {
  background-color: #254260 !important;
}

.bg-dark1 {
  background-color: #254260 !important;
}

.bg-white-alternate {
  background: #254260;
}

.bg-black-alternate {
  background: rgb(150 75 0 / 84%);
}

.bg-cancelb {
  background-color: #740000 !important;
  border-color: #740000;
  color: #fff;
}

.invalid-feedback{
  color: #d30000 !important;
}

.selected-item {
  margin-top: 4px;
  background-color: #254260 !important;
  border: 0px !important;
}

li.multiselect-item-checkbox>div:before {
  border-color: #00000088 !important;

}

hr {
  background-color: #254260 !important;
  height: 1px;
}

.sidebar {
  .card {
    border: 0px !important;
  }

  div[role="tabpanel"] {
    max-height: 300px;
    overflow-y: auto;
  }
}

/* Devider */
.text-divider {
  margin: 2em 0;
  line-height: 0;
  text-align: center;
}

.text-divider span {
  background-color: #f5f5f5;
  padding: 0 1em;
}

.text-divider:before {
  content: " ";
  display: block;
  border-top: 1px solid #e3e3e3;
  border-bottom: 1px solid #f7f7f7;
}

/* accordian header */
.filter .accordion>.card>.card-header {
  padding: 0 !important;
  background-color: transparent !important;
  border: 0;
}


/* scrollbar */
::-webkit-scrollbar {
  width: 4px !important;
  height: 4px !important;
}

::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #555 !important;
  border-radius: 10px !important;
}


// pppppppppppppppppppppppp//
.heading-double {
  display: table;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  padding-left: 55px;
  padding-right: 55px;
}

.heading-double:before {
  right: 100%;
  margin-right: -50px;
  content: "";
  width: 45px;
  height: 3px;
  background: #254260;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.heading-double:after {
  left: 100%;
  margin-left: -50px;
  content: "";
  width: 45px;
  height: 3px;
  background: #254260;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

////tooltip

