.btn-theme-alternate-1 {
  // color: white;
  // background-image: -moz-linear-gradient(47deg, #ee8219 0, #ef4559 100%);
  // background-image: -webkit-linear-gradient(47deg, #ee8219 0, #ef4559 100%);
  // box-shadow: 0 8px 29px 0 rgba(239, 139, 117, 0.6);

  color: rgb(0, 0, 0);
  background-color: #e2e2e2;
  border-color: #e2e2e2;
}
// i-bs
// {
//     // color: #254260;
// }
.btn-theme-alternate-1:hover {
  color: rgb(255, 250, 250);
  background-color: #254260;
  border-color: #ffffff;
  transition: all .4s;
}

.bg-them-pink-1 {
  background-color: #254260 !important;
}

.bg-them-danger-1 {
  background-color: #254260 !important;
}
.bg-them-danger-2 {
  background-color: #254260 !important;
}
.link-theme-danger-1 {
  color: #ececec !important;
  background-color: #254260 !important;
}
.fontcolor {
  
  color: #254260 !important;
}
.border-them-danger-1 {
  border-color: #e2e2e2 !important;
}

.shadow-theme-alternate-1 {
  box-shadow: 0 0px 20px 0 rgba(0, 0, 0, 0.125);
}

.text-alternate-color-1 {
  background: #F89406;
  background: -webkit-linear-gradient(to right, #F89406 0%, #ff6600 100%);
  background: -moz-linear-gradient(to right, #F89406 0%, #ff6600 100%);
  background: linear-gradient(to right, #F89406 0%, #ff6600 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-alternate-color-2 {
  background: #f08d03;
  background: -webkit-linear-gradient(to right, #f79103 0%, #eb5e00 100%);
  background: -moz-linear-gradient(to right, #f79103 0%, #eb5e00 100%);
  background: linear-gradient(to right, #f79103 0%, #eb5e00 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.job-details-container {
  .edu-grid {
    background-color: #dc354522 !important;
  }
  .gutterx-none
  {
    --bs-gutter-x:0rem !important;
  }
}


.table-grid {
  background-color: #98b4d13d !important;
}
.tablegutterx-none
{
  --bs-gutter-x:0rem !important;
}

.text-pink{
  color:#f86c84;
}

.carouselitem {
  
}
.popupfontc1
{
  font-weight: 400 !important;
}
.ct-title-bb:after {
  display: block;
  position: absolute;
  width: 4.928rem;
  height: .2875rem;
  content: "";
  margin-top: 10px;
  background: #e2e2e2!important;
}
.ct-title-bb-center:after {
  display: block;
  position: absolute;
  width: 4.928rem;
  height: .2875rem;
  content: "";
  margin-top: 10px;
  background: #e2e2e2!important;
}

.slide
{
    //width: 170px !important;
    
}


.sidebarborder-bottom {
  border-bottom: 2px solid #254260 !important;
  /* border-width: 3px !important; */
}
